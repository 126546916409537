import React from 'react';
import { useTranslation } from 'react-i18next';

const SaveTheWavesButton = () => {
  const {t} = useTranslation();

  return (
    <div className='save-the-waves-container'> 
      <a href='http://savethewaves.org/app/submitonline/?utm_source=lineup.surf&utm_medium=referral&utm_campaign=app_promotion' target='_blank' className='normalize-button save-the-waves-button'>
        <img src={require("../assets/icons/save-the-waves-logo-short.png")}/>
        {t("save_the_waves_report_button")}
      </a>
    </div>
  )
}

export default SaveTheWavesButton
