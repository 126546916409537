import store from "../utils/store";

class PageReducer {

  saveSpotsState(state){
    store.dispatch({
      type: "SPOTS_PAGE_STATE",
      data: state,
    });
  };

  resetSpotsState(){
    store.dispatch({
      type: "SPOTS_PAGE_STATE",
      data: undefined
    });
  };

}

const pageReducer = new PageReducer();

export default pageReducer;